<template>
  <main id="ProductPage">
    <div>
      <template v-if="product_data != null">
        <Cover
          :options="product_data.options"
          :option_combine="product_data.option_combine"
          :product_data="product_data"
          v-model="active_options"
        />
        <Info :product_data="product_data" />
      </template>
      <div class="dealer_box">
        <div class="content_box">
          <h4>經銷店家</h4>
          <p>尋找您附近的經銷店家，立即前往訂購您喜愛的商品</p>

          <router-link to="/dealer" class="more_btn">
            <div><b>立即前往</b></div></router-link
          >
        </div>
        <div class="img_box">
          <img src="/img/dealer_bg.jpg" />
        </div>
      </div>
      <MainFooter />
    </div>
  </main>
</template>

<script>
import SmoothScrollResister from '@/common/SmoothScrollResister.js';
import ProductPageGSAP from '@/common/GSAP/Products/product_page.js';
import MainFooter from '@/components/MainFooter';
import Cover from '@/components/Product/Cover';
import Info from '@/components/Product/Info';
import { getProduct } from '@/api/product.js';
import { GetMetaData } from '@/common/meta.js';
import { ReturnErrorPage } from '@/common/prerender_event';
export default {
  name: 'ProductPage',
  mixins: [SmoothScrollResister, ProductPageGSAP],
  components: {
    MainFooter,
    Cover,
    Info,
  },
  data() {
    return {
      product_data: null,
      active_options: '',
      metainfo_title: '',
      metainfo_description: '',
      metainfo_image: '',
      meta_data: null,
    };
  },
  methods: {
    async GetProduct() {
      getProduct(this.$route.params.id).then((res) => {
        if (res.data != null) {
          this.product_data = res.data;
          this.active_options = this.product_data.option_combine.filter(
            (item) => item.status == 'Y' && item.stock > 0
          )[0].combine_id;

          this.metainfo_title = this.product_data.name;
          this.metainfo_description = this.product_data.description.replace(
            /<[^>]+>/g,
            ''
          );
          this.metainfo_image = this.product_data.cover;

          this.meta_data = GetMetaData(
            this.metainfo_title,
            this.metainfo_description,
            this.metainfo_image
          );

          this.GA_ViewProduct(this.product_data);
          this.$nextTick(() => {
            this.RegisterGsap('ProductPage');
            this.InitCard(-1);
            this.InitGsapMarker();
            window.prerenderReady = true;
          });
        } else {
          ReturnErrorPage();
        }
      });
    },
  },
  async created() {
    await this.GetProduct();
  },
  metaInfo() {
    return this.meta_data;
  },
};
</script>
