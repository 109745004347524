<template>
  <section id="ProductCover">
    <div class="row">
      <div class="img_box">
        <img :src="product_data.cover" />
      </div>
      <div class="content_box">
        <div class="row_box">
          <div class="title_box box">
            <span>{{ product_data.category }}</span>
            <h2>{{ product_data.name }}</h2>
          </div>
          <div class="info_box box">
            <p>適用車種：</p>
            <p>{{ product_data.info }}</p>
          </div>
          <div class="box">
            <div
              class="option_box"
              v-for="(option_type, option_type_index) in product_data.options"
              :key="`options_type_${option_type.option_type_id}`"
            >
              <p>{{ option_type.title }}:</p>
              <div class="select_box">
                <select
                  @change="ChangeOption(option_type_index, $event.target.value)"
                >
                  <option
                    v-for="item in option_type.options"
                    :value="item.option_id"
                    :key="`option_${item.option_id}`"
                  >
                    {{ item.title }}
                  </option>
                </select>
                <span class="material-icons-outlined test"> expand_more </span>
              </div>
            </div>
          </div>

          <div class="price_box box">
            <p>
              建議售價：<span v-if="GetPrice()[0] != GetPrice()[1]"
                >原價{{ GetPrice()[0] }}</span
              >
            </p>
            <p class="price">NT$ {{ GetPrice()[1] }}</p>
          </div>
          <button @click="OpenMessenger" class="message_btn box">
            <span class="material-icons-outlined"> mode_comment </span
            >透過Messenger詢問
          </button>
          <div class="share_box box">
            <p><span class="material-icons-outlined"> share </span>分享</p>
            <a @click="ShareToFB"><img src="/img/fb_icon.svg" /></a>
            <a @click="ShareToLine"><img src="/img/line_icon.png" /></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProductCoverGSAP from "@/common/GSAP/Products/product_cover.js"
export default {
  name: "ProductCover",
  props: {
    value: {
      require: true,
      type: String
    },
    options: {
      require: true,
      type: Array
    },
    option_combine: {
      require: true,
      type: Array
    },
    product_data: {
      require: true
    }
  },
  mixins: [
    ProductCoverGSAP
  ],
  data() {
    return {
      active_option: 0
    }
  },
  methods: {
    OpenMessenger() {
      window.open('http://m.me/MJP.Factory', "_blank")
    },
    GetPrice() {
      if (this.product_data.price_type == "P") {
        return [this.product_data.price, this.product_data.sale_price]
      }
      else {
        return [this.option_combine.filter(item => item.combine_id == this.value)[0].price, this.option_combine.filter(item => item.combine_id == this.value)[0].price]
      }
    },
    ChangeOption(index, val) {
      let combine = this.option_combine.filter(item => item.combine_id == this.value)[0]
      let active_option_id = combine.option_id_group.split(",")
      active_option_id[index] = val
      let id_group = ""
      for (let i = 0; i < active_option_id.length; i++) {
        id_group += active_option_id[i]
        i == active_option_id.length - 1 ? "" : id_group += ","
      }

      if (this.option_combine.filter(item => item.option_id_group == id_group)[0].status != "Y") {
        //這個選項不開放選購 開始由下往上找品項
        // 1,2,3
        let avalible_combine = -1
        id_group = id_group.split(",")
        let count = 1
        for (let i = 0; i < 1; i++) {
          let tmp_group = id_group.slice(0, id_group.length - count)
          let result = this.GetAvalibleCombine(tmp_group)
          if (result.length <= 0) {
            count += 1
            i -= 1
          }
          else {
            avalible_combine = result[0].combine_id
            i = 2
          }
        }

        this.$emit("input", avalible_combine)
      }
      else {
        this.$emit("input", this.option_combine.filter(item => item.option_id_group == id_group)[0].combine_id)
      }

    },
    GetOptionValue(index) {
      let combine = this.option_combine.filter(item => item.combine_id == this.value)[0]
      let active_option_id = combine.option_id_group.split(",")[index]
      return this.options[index].options.filter(item => item.option_id == active_option_id)[0]
    },
    GetAvalibleOption(index) {
      if (index == 0) {
        return this.options[index].options
      }
      else {
        let combine = this.option_combine.filter(item => item.combine_id == this.value)[0]
        let active_option_id = combine.option_id_group.split(",")
        active_option_id = active_option_id.slice(0, index)

        let include_array = this.option_combine.filter(item => {
          let include = true
          active_option_id.forEach(element => {
            item.option_id_group.split(",").indexOf(element) == -1 ? include = false : ""
          });
          return include == true ? item : ""
        })
        include_array = include_array.filter(item => item.status == "Y")
        let allow_ids = []
        include_array.forEach(item => {
          allow_ids.push(item.option_id_group.split(",")[index])
        })
        return this.options[index].options.filter(item => allow_ids.indexOf(item.option_id) != -1)
      }
    },
    GetAvalibleCombine(group) {
      let ids = ""
      for (let i = 0; i < group.length; i++) {
        ids += group[i]
        ids += ","
      }
      let result = this.option_combine.filter(item => item.option_id_group.indexOf(ids) != -1)
      return result
    },

    ShareToFB() {
      window.open(`https://www.facebook.com/sharer.php?u=${window.location.href}`, '_blank').focus();
    },
    ShareToLine() {
      window.open(`line://msg/text/${window.location.href}`, '_blank').focus();
    },
    CopyLink() {
      /* Copy the text inside the text field */
      // navigator.clipboard.writeText(window.location.href);
      var copyText = document.getElementById("UrlInput");
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      document.execCommand("copy");

      /* Alert the copied text */
      alert("已複製連結至剪貼簿");
    }
  },
  watch: {
    // value() {
    //   if (this.$refs.ProductImage) {
    //     let option = this.option_combine.filter(item => item.combine_id == this.value)[0]
    //     option.image == "" ? this.$refs.ProductImage.ChangeOptionImage(this.product_data.cover) : this.$refs.ProductImage.ChangeOptionImage(option.image)
    //   }
    // }
    product_data() {
      if (this.product_data != null) {
        this.SetUp()
      }
    }
  },
  mounted() {
    if (this.product_data != null) {
      this.SetUp()
    }
  }
}
</script>