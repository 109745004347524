import {
    get,
    post
} from '@/common/request'

export function getProductList() {
    return get('products/get_product_list.php')
}

export function getProduct(id) {
    return post('products/get_product.php', {
        id: id
    })
}